import axios, { AxiosError } from 'axios';

export enum TypeFilters {
  MEDIA,
  STRING
}

export type RedditListingResponse = {
  kind: string,
  data: {
    after: string,
    disat: number,
    children: RedditPost[]
  }
}

export type RedditPost = {
  kind: string,
  data: {
    id: string,
    title: string,
    author: string,
    subreddit: string;
    subreddit_name_prefixed: string;
    media_metadata: {[key:string]:{o:{x:number, y: number, u:string}[]}}
    thumbnail: string,
    is_gallery: boolean,
    gallery_data?: {items: {media_id: string, id: number}[]}
    post_hint?: "image" | "video" | "link",
    url: string,
    preview?: {
      images: {source:{url: string, width: number, height: number}}[],
      reddit_video_preview?: { fallback_url: string }
    },
  }
}

export enum PostType {
  IMAGE,
  GALLERY,
  VIDEO,
  TEXT
}

export type Post = {
  id: string;
  subreddit: string;
  type: PostType;
  title: string;
  author: string;
  preview: string;
  fileUrl: string;
  gallery?: string[];
  raw: RedditPost;
}

export class RedditScraper {

  listingUrl: string;
  rawData = {} as RedditListingResponse;
  results = [];
  typeFilter = [];

  constructor(listingUrl:string) {
    this.listingUrl = this.correctUrlToJsonSource(listingUrl);
  }

  setTypeFilter() {

  }

  getResults() {
    let res: Post[] = [];
    if(!this.rawData?.data?.children) return res;

    this.rawData.data.children.forEach(p => {

      if (!p.data.post_hint && !p.data.is_gallery ) return;
      if (p.data.post_hint && p.data.post_hint == 'link') return;
      if (/reddit.com\/.*\/comments\/.*/.test(p.data.url)) return;

      //if (p.data.url.indexOf('ihttps://www.redgifs.com/watch/') > -1) return;
      //if (p.data.url.indexOf('imgur.com') > -1) return;
      // Check tsnyphs for redgif images...

      let preview = p.data.thumbnail || "";
      let file = "none";
      let gallery: string[] = [];
      let type = PostType.IMAGE;

      if (p.data.preview) {
        file = p.data.url;//p.data.preview.images[0].source.url;
      }

      if (p.data.is_gallery) {
        gallery = p.data.gallery_data!.items.map(g => `https://i.redd.it/${g.media_id}.jpg`)
        file = gallery[0]
        type = PostType.GALLERY
      }

      if (p.data.preview?.reddit_video_preview) {
        file = p.data.preview.reddit_video_preview.fallback_url;
        type = PostType.VIDEO
      }

      res.push({
        id: p.data.id,
        author: p.data.author,
        subreddit: p.data.subreddit_name_prefixed,
        title: p.data.title,
        preview: preview,
        fileUrl: file,
        type,
        gallery,
        raw: p
      })
    });
    return res;
  }

  async fetch(additionalParams: string = "") {
    if (this.listingUrl.length > 3) {
      try {
        const res = await axios.get(this.listingUrl + additionalParams)
        this.rawData = res.data
      } catch (error) {
        //alert('Could not fetch: ' + this.listingUrl + additionalParams)
        throw new Error("Fetch Error for URL: " + this.listingUrl + additionalParams);

      }
    }
  }

  async nextPage() {
    let pager = "";
    if(this.rawData?.data?.after) {
      pager = "?after=" + this.rawData?.data?.after
    }
    await this.fetch(pager)
  }

  correctUrlToJsonSource(url: string) {
    if (url.length < 3) return '';
    if (url.indexOf('https://www.reddit.com/') < 0) {
      url = 'https://www.reddit.com/' + url;
    }
    if (url.substring(url.length - 5) != ".json") {
      if (url.substring(url.length - 1) == "/") {
        url = url.substring(0, url.length - 1) + ".json"
      } else {
        url = url + ".json"
      }
    }
    return url;
  }

}
